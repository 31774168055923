
//Trengo chat integration

//helper function..

function setTrengoContactData(contactEmail, contactName, teamleaderGUID){

    if (contactEmail != 'Unknown' && contactName != 'Unknown') {
        console.log ('Setting chat contact data...');

        if (teamleaderGUID != undefined &&  teamleaderGUID != 'Unknown'){
            console.log ('With TLGUID...');
            window.Trengo.contact_data ={
                email: contactEmail,
                name: contactName,
                custom_fields: [
                    { field_id: 84385, value: teamleaderGUID}
                ]
            };
        } else {
            console.log ('Without TLGUID...');
            window.Trengo.contact_data ={
                email: contactEmail,
                name: contactName
            };
        }
        
    } else {
        console.log ('Chat contact data unknown');
    }    
}

function setTrengoTicketData(ticketData){ 
    if(ticketData != undefined) {
        console.log ("Setting chat ticket data...");

        window.Trengo.ticket_data = {
            custom_fields: [
                { field_id: 37976, value: ticketData }
            ]
        };
    } else {
        console.log ("No chat ticket data set...");
        window.Trengo.ticket_data = undefined;
    }

}

function openChat(contactName, contactEmail, teamleaderGUID, ticketData){

    var keyOnline =  'TRENGO_KEY_ONLINE';
    var keyOffline = 'TRENGO_KEY_OFFLINE';
    

    window.Trengo = window.Trengo || {};
    if (contactEmail != 'Unknown' && contactName != 'Unknown') {
        console.log("Setting chat for online");
        window.Trengo.key = keyOnline;
    } else {
        console.log("Setting chat for offline");
        window.Trengo.key = keyOffline;
    }
    
    window.Trengo.launcherOnlineStatus = true;

    window.Trengo.extraOffsetX = '12px';
    window.Trengo.extraOffsetY = '5rem';
    

    if (window.Trengo.Api != undefined){
        console.log("Trengo already initialized");

        setTrengoContactData(contactEmail, contactName, teamleaderGUID);

        setTrengoTicketData(ticketData);

        window.Trengo.extraOffsetY = '5rem';

        window.Trengo.Api.Widget.open('chat');
        return;
    }

    setTrengoContactData(contactEmail, contactName, teamleaderGUID);
    setTrengoTicketData(ticketData);

    (function(d, script, t){ 
        script = d.createElement('script'); 
        script.type = 'text/javascript'; 
        script.async = true; 
        script.src = 'https://static.widget.trengo.eu/embed.js'; 
        d.getElementsByTagName('head')[0].appendChild(script); 
    }(document));

    window.Trengo.on_ready = function() {
        //console.log('the widget is ready!');
        window.Trengo.Api.Widget.open('chat');
    };

    window.Trengo.on_chat_ended = function() {
        console.log('the chat has ended');
        window.Trengo.extraOffsetY = '-1200rem';
    };

    /*
    window.Trengo.on_close = function() {
        console.log('the widget is closed.');
        //window.Trengo.extraOffsetY = '-1200rem';
    };
    window.Trengo.on_open = function() {
        console.log('the widget is opened.');
    };
    
    window.Trengo.on_status_online = function() {
        console.log('the chat is online');
    };
    window.Trengo.on_status_offline  = function() {
        console.log('the chat is offline');
    };
    window.Trengo.on_chat_started = function() {
        console.log('the chat has started');
    };*/
  
}

function unInitializeChat() {

    try{
        var trengoChatDiv = document.getElementById("trengo-web-widget");
        trengoChatDiv.parentElement.removeChild(trengoChatDiv);

        
        if (window.Trengo != undefined){
            if( window.Trengo.Api != undefined)
            {
                window.Trengo.Api = undefined;
            }
            window.Trengo = undefined;

        }
    }
    catch(error){
        console.log("Error removing trengo chat div");
    }
}
//Trengo end


//User onboarding script
function startOnboardingTour(userDetails, faqOnboarding){



    if (    userDetails != undefined 
            && userDetails.userId 
            && userDetails.userName
            && userDetails.email
            && userDetails.dateCreated
            
       ){


        console.log ("startOnboardingTour starting...");
        (function(){ 
            var s = document.createElement('script')
            s.src = 'https://js.getuserflow.com/userflow.js'
            s.async = true
            document.head.appendChild(s)
            s.onload = function() {

                console.log ("startOnboardingTour loaded...");
                userflow.init('ONBOARDING_KEY')
                userflow.identify (
                    userDetails.userId,
                    { 
                        "name": userDetails.userName, 
                        "email": userDetails.email, 
                        "signed_up_at": userDetails.dateCreated 
                    }
                )
                if (faqOnboarding == "true"){
                    userflow.start('b0075683-b354-4af3-85af-9cab37140390');
                }
            }
        }(document));
    }
    
}
//User onboarding script end

